import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loginUser,
  forgotPassword,
  setUser,
  checkUserProvider,
} from "../../../actions/authActions";
import "../index.scss";
import backgroundImage from "../../../assets/Login-Image.png";
import logo from "../../../assets/logo.png";
import { firebaseobj } from "../firebase";
import { bindActionCreators } from "redux";
import LoadingOverlay from "../../../Utils/Loader/loader";
import * as firebaseui from "firebaseui";
import { VALID_EMAIL } from "../regex";
import { Grid } from "@material-ui/core";
import LoginForm from "./login";
import FirebaseLogin from "./firebaseUI";
import Copyright from "./copyright";
import DomainComponent from "./domain";
import Button from "@material-ui/core/Button";
import PhoneInTalkOutlinedIcon from "@material-ui/icons/PhoneInTalkOutlined";

class Login extends Component {
  constructor(props) {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      isEmailUser: false,
      uiconfigs: [],
      FirebaseUIConfig: {
        signInOptions: [],
        credentialHelper: "none",
        signInFlow: "popup",
        callbacks: {
          signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            return false;
          },
          signInFailure: (error) => {},
        },
      },
      inputError: {},
    };
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {
    // let loc = new URL(window.location.origin);
    //To configure Tenant UI info. Currently For POC, all the three providers have been included
    //Here make an api call to get the tenantID
    //TO do API call using axios and get the sign in options
    //If there is no tenant ID coming from the backend, we won't display any signin options to the user
    // if (!this.props.authReducer.isAuthenticated && this.props.tenantId) {
    if (!this.props.authReducer.isAuthenticated) {
      this.setFirebaseUI(this.props.tenantId);
    }
    this.unregisterAuthObserver = firebaseobj
      .auth()
      .onAuthStateChanged((user) => {
        if (
          user &&
          this.props.authReducer.isTokenVerified &&
          this.props.authReducer.isAuthenticated
        ) {
          this.props.history.push(this.props.landingPage);
        } else {
          if (
            this.state.uiconfigs.length > 0 &&
            (this.state.uiconfigs[0] || this.state.uiconfigs[1])
          ) {
            let firebaseUIInstance = firebaseui.auth.AuthUI.getInstance();
            if (!firebaseUIInstance) {
              firebaseUIInstance = new firebaseui.auth.AuthUI(
                firebaseobj.auth()
              );
            } else {
              firebaseUIInstance.reset();
            }
            firebaseUIInstance.start(
              "#firebaseui_container",
              this.state.FirebaseUIConfig
            );
          }
        }
      });
  }

  componentWillUnmount() {
    //Unregistering the auth oberserver that is called inside component did mount
    //This will clean the memory for the observer
    this.unregisterAuthObserver();
  }

  setFirebaseUI(tenantId) {
    let signInBooleanArray = [true, false, true]; //This enables the respective sign in option => 1. Google 2. SAML 3. Email/Password
    // let signInOptions = tenantId.sign_in_options;
    // for (let i = 0; i < signInOptions.length; i++) {
    //   signInBooleanArray[signInOptions[i] - 1] = true;
    // }
    let FirebaseUIobj = this.state.FirebaseUIConfig
    if (signInBooleanArray[0]) {
      FirebaseUIobj["signInOptions"].push({
        provider: firebaseobj.auth.GoogleAuthProvider.PROVIDER_ID,
      });
    }

    if (signInBooleanArray[1]) {
      FirebaseUIobj["signInOptions"].push({
        provider: tenantId.tenant_name,
        providerName: "Microsoft SSO",
        buttonColor: "#fff",
        iconUrl:
          "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/microsoft.svg",
      });
    }
    this.setState({
      uiconfigs: signInBooleanArray,
      FirebaseUIConfig: FirebaseUIobj,
    });
  }

  onChange(e) {
    if (e.target.name == "email") {
      this.setState({ isEmailUser: false });
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  ResetPassword = async () => {
    await this.checkUserProvider();
    if (this.verifyUserData() && this.state.isEmailUser) {
      this.props.forgotPassword(this.state.email);
      this.clearError();
    } else {
      this.setState({ inputError: { email: "Please input a valid email" } });
    }
  };

  componentDidUpdate() {
    if (
      this.props.authReducer.isTokenVerified &&
      this.props.authReducer.isAuthenticated
    ) {
      this.props.history.push(this.props.landingPage);
    }
    if (
      !this.props.authReducer.isAuthenticated &&
      // this.props.authReducer.tenantId &&
      !this.state.FirebaseUIConfig
    ) {
      this.setFirebaseUI(this.props.tenantId);
    }
    if (this.props.errors) {
      this.setState({ errors: this.props.errors });
    }
  }

  verifyUserData = () => {
    let error = {};
    if (!this.state.email || !VALID_EMAIL.test(this.state.email)) {
      error.email = "Please input valid email id";
    }
    if (!this.state.password) {
      error.password = "Please enter password";
    }
    if (Object.keys(error).length) {
      this.setState({ inputError: error });
      return false;
    } else {
      return true;
    }
  };
  clearError = () => {
    if (Object.keys(this.state.inputError).length) {
      this.setState({ inputError: {} });
    }
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.verifyUserData()) {
      this.clearError();
      const userData = {
        email: this.state.email,
        password: this.state.password,
      };
      this.props.loginUser(userData);
    }
  };

  checkUserProvider = async (e) => {
    if (VALID_EMAIL.test(this.state.email)) {
      try {
        const providers = await this.props.checkUserProvider(this.state.email);
        if (providers[0] == "password") {
          this.setState({ isEmailUser: true });
        } else {
          this.setState({ isEmailUser: false });
        }
      } catch (error) {
        this.setState({ isEmailUser: false });
      }
    }
  };
  render() {
    var currentTime = new Date();
    var year = currentTime.getFullYear();
    return (
      <>
        <LoadingOverlay
          loader={this.props.overlayLoaderState}
          text={this.props.loaderText}
          spinner
        >
          <Grid container>
            <Grid item xs={4}>
              <Copyright
                year={year}
                logo={logo}
                backgroundImage={backgroundImage}
              />
            </Grid>
            <Grid item xs={8} style={{ backgroundColor: "#fbfbfb" }}>
              {/* {!this.props.isTenantInfoFetched ? (
                <DomainComponent />
              ) : ( */}
                <div id="signInForm" className="h-md-100 signin-form-container">
                  <div className="contact-btn-container"><Button
                    variant="outlined"
                    startIcon={<PhoneInTalkOutlinedIcon />}
                    className="contact-btn"
                  >
                    Contact Us
                  </Button></div>
                  
                  <div className="login-options">
                    {/* {this.state.uiconfigs[2] && ( */}
                        <LoginForm
                          email={this.state.email}
                          password={this.state.password}
                          onChange={this.onChange}
                          ResetPassword={this.ResetPassword}
                          onSubmit={this.onSubmit}
                          error={this.state.inputError}
                        />
                    {/* // )} */}
                    {/* {(this.state.uiconfigs[0] || this.state.uiconfigs[1]) && ( */}
                      <>
                      <div className="partitioner">
                          <div className="partial-partition"></div>
                          <div className="partition-or">or</div>
                          <div className="partial-partition"></div>
                        </div>
                      <div
                        id="firebaseSignInOptionsDiv"
                        className={
                          this.state.uiconfigs[2]
                            ? "firebase-sign-in"
                            : "firebase-without-emailLogin"
                        }
                      >
                        <FirebaseLogin
                          FirebaseUIConfig={this.state.FirebaseUIConfig}
                          firebaseobj={firebaseobj}
                        />
                      </div>
                      </>
                    {/* // )} */}
                  </div>
                </div>
              {/* // )} */}
            </Grid>
          </Grid>
        </LoadingOverlay>
      </>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  // errors: PropTypes.object.isRequired,
  // success: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  isTenantInfoFetched: state.authReducer.isTenantInfoFetched,
  isUsrSessionChecked: state.authReducer.isUsrSessionChecked,
  overlayLoaderState: state.loaderReducer.overlayLoaderState,
  loaderText: state.loaderReducer.loaderText,
  errors: state.errors,
  showAlert: state.authReducer.showAlert,
  authErrMsgProperties: state.authReducer.alertProperties,
  // success: state.success,
  landingPage: state.authReducer.landingPage,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { loginUser, setUser, forgotPassword, checkUserProvider },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
