import moment from "moment";
// TESTTT
const initialState = {
  // Timeline Filter
  currYear: null,
  compareYear: null,
  isLineChartLoading: false,
  resetDeepdive: false,
  resetInventory: false,
  showTimeLineButtons: false,
  timeLine: "lw",
  dateOrWeek: "week",
  endDate: moment(),
  startDate: moment().subtract(7, "d"),
  weekStartDate: null,
  weekEndDate: null,
  labels: {},
  // Channel
  channel: {},

  //  Compared To
  comparedToYear: moment().format("YYYY"),
  showCompareButtons: false,
  comparedTo: "LY",

  showMerchandizingModal: false,
  showRegionModal: false,

  selectedProductfilters: {},
  selectedStorefilters: {},
  dropDowns: {},
  dropDownsDownFilters: {},
  selectedFilters: {},
  labelMapper: {},
  compare_years: [],

  startDateTimeline: null,
  endDateTimeline: null,
  startFwWeekTimeline: null,
  endFwWeekTimeline: null,
  startDateCompared: null,
  endDateCompared: null,
  startFwWeekCompared: null,
  endFwWeekCompared: null,
  kpiData: [],
  kpiList: [],
  barGraphDataRegion: [],
  barGraphDataCategory: [],
  barGraphDeepDive: [],
  scatterGraphDeepDive: [],
  // ['transactions', 'quantity', 'avg_basket_size', 'sales', 'aur', 'avg_basket_value',
  // 'margin', 'aum', 'gross_margin', 'overall_promo_perc', 'avg_promo_perc', 'promo_units', 'promo_sales', 'promo_margin', 'avg_discount_perc', 'discount_units',
  // 'discount_sales', 'discount_margin', 'sell_through', 'inv_eop', 'inv_bop', 'woi']
  // kpiList: ['quantity', 'woi', 'sales', 'margin'],
  // selectedKpiList: ['quantity', 'woi', 'sales', 'margin'],
  // kpiList: [],
  selectedKpiList: [],
  valueKpiCardTimeLine: {},
  valueKpiCardCompare: {},
  lineData: [],
  ytdStartDate: null,
  ytdEndDate: null,
  ytdStartDateFw: null,
  ytdEndDateFw: null,
  kpiListData: [],
  kpiCategories: [],
  kpiItems: {},
  kpi_Values: {},
  heatMapData: {},
  heatMapDivisions: [],
  heatMapDepartments: [],
  heatMapRegions: [],
  heatMapKpis: [],
  heatMapLevel: "l0_name",
  allHD: {},
  regionSalesData: {},
  startDateLineCompared: null,
  endDateLineCompared: null,
  startFwWeekLineCompared: null,
  endFwWeekLineCompared: null,

  metricsOverview: {},
  metricsDeepdive: {},
  topKpiOverview: {},
  topKpiDeepdive: {},
  top_kpi_list_overview: [],
  top_kpi_list_deepdive: [],
  top_kpi_list_default: [],
  metrics_list_overview: [],
  metrics_list_deepdive: [],
  inv_list_deepdive: [],
  inv_list_overview: [],
  allKpiLabelMapper: {},
  kpiCompList: [],
  appliedCompareYear: null,
  appliedTimelineYear: null,
  events: [],
  selectedHeatmapDivision: null,
  nav: false,
  storeData: [],
  mapView: "DC",
  handleApplyInventory: false,
  handleApplyDeepdive: false,
  invKpiValue: null,
  filterChanged: false,
  callApplyHeatmap: false,
  scatterDeepDiveData: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case "HANDLE_CHECKBOX":
      var { prop1, prop2 } = action.payload;
      return {
        ...state,
        [prop1]: {
          ...state[prop1],
          [prop2]: !state[prop1][prop2],
        },
      };

    case "HANDLE_SWITCH":
      var { prop } = action.payload;
      return {
        ...state,
        [prop]: !state[prop],
      };

    case "HANDLE_BUTTON_CLICK":
      var { prop1, prop2 } = action.payload;
      return {
        ...state,
        [prop1]: prop2,
      };

    case "UPDATE_SELECTED_FILTER":
      var { prop1, prop2 } = action.payload;
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [prop1]: prop2,
        },
      };

    case "HANDLE_DATE_CHANGE":
      var { prop1, value } = action.payload;
      if (prop1 === "week") {
        return {
          ...state,
          weekStartDate: value.startDate,
          weekEndDate: value.endDate,
        };
      } else {
        return {
          ...state,
          startDate: value.startDate,
          endDate: value.endDate,
        };
      }
    case "HANDLE_RESEST":
      return initialState;

    case "SET_CHANNELS":
      const allChannels = action.channels.data.data.dimension_attributes;
      const channelsObject = {};
      allChannels.forEach((thisChannel) => {
        channelsObject[thisChannel] = true;
      });

      return {
        ...state,
        channel: channelsObject,
      };

    case "SET_LABELS":
      return {
        ...state,
        labels: action.payload,
      };
    case "SET_YEARS":
      return {
        ...state,
        compare_years: action.years,
      };
    case "HANDLE_FILTER_SWITCH":
      var { prop1, prop2, asi, its } = action.payload;
      const tempObj = {};
      const keys = Object.keys(state[prop1]);
      for (let i = 0; i < keys.length; i++) {
        tempObj[keys[i]] = false;
      }
      // Going for its selection
      var largerIndex = 0;
      if (asi >= its) {
        largerIndex = asi;
      } else {
        largerIndex = its;
      }
      if (!state[prop1][prop2]) {
        for (let i = 0; i <= largerIndex; i++) {
          tempObj[keys[i]] = true;
        }
      } else {
        if ((largerIndex = its)) {
          for (let i = 0; i <= its - 1; i++) {
            tempObj[keys[i]] = true;
          }
        } else {
          return {
            ...state,
          };
        }
      }
      return {
        ...state,
        [prop1]: {
          ...tempObj,
        },
      };
    case "SET_SELECTED_TIMELINE_START_DATE":
      return {
        ...state,
        startDateTimeline: action.payload,
      };
    case "SET_SELECTED_TIMELINE_END_DATE":
      return {
        ...state,
        endDateTimeline: action.payload,
      };
    case "SET_SELECTED_TIMELINE_START_FISCAL_WEEK":
      return {
        ...state,
        startFwWeekTimeline: action.payload,
      };
    case "SET_SELECTED_TIMELINE_END_FISCAL_WEEK":
      return {
        ...state,
        endFwWeekTimeline: action.payload,
      };
    case "SET_SELECTED_COMPARED_START_DATE":
      return {
        ...state,
        startDateCompared: action.payload,
      };
    case "SET_SELECTED_COMPARED_END_DATE":
      return {
        ...state,
        endDateCompared: action.payload,
      };
    case "SET_SELECTED_COMPARED_START_FISCAL_WEEK":
      return {
        ...state,
        startFwWeekCompared: action.payload,
      };
    case "SET_SELECTED_COMPARED_END_FISCAL_WEEK":
      return {
        ...state,
        endFwWeekCompared: action.payload,
      };

    case "SET_SELECTED_COMPARED_LINE_START_DATE":
      return {
        ...state,
        startDateLineCompared: action.payload,
      };
    case "SET_SELECTED_COMPARED_LINE_END_DATE":
      return {
        ...state,
        endDateLineCompared: action.payload,
      };
    case "SET_SELECTED_COMPARED_LINE_START_FISCAL_WEEK":
      return {
        ...state,
        startFwWeekLineCompared: action.payload,
      };
    case "SET_SELECTED_COMPARED_LINE_END_FISCAL_WEEK":
      return {
        ...state,
        endFwWeekLineCompared: action.payload,
      };

    case "SET_YTD_START_DATE":
      return {
        ...state,
        ytdStartDate: action.payload,
      };
    case "SET_YTD_END_DATE":
      return {
        ...state,
        ytdEndDate: action.payload,
      };
    case "SET_YTD_START_DATE_FW":
      return {
        ...state,
        ytdStartDateFw: action.payload,
      };
    case "SET_YTD_END_DATE_FW":
      return {
        ...state,
        ytdEndDateFw: action.payload,
      };
    case "SET_KPI_LIST_DATA":
      return {
        ...state,
        kpiListData: action.kpiListData,
      };
    case "SET_KPI_ITEMS":
      return {
        ...state,
        kpiItems: action.kpiItems,
      };
    case "SET_HEATMAP_DATA":
      return {
        ...state,
        heatMapData: action.heatMapData,
      };
    case "ALL_HD_DATA":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_HEATMAP_DIVISIONS":
      return {
        ...state,
        heatMapDivisions: action.heatMapDivisions,
      };
    case "SET_HEATMAP_DEPARTMENTS":
      return {
        ...state,
        heatMapDepartments: action.heatMapDepartments,
      };
    case "SET_HEATMAP_LEVEL":
      return {
        ...state,
        heatMapLevel: action.heatMapLevel,
      };
    case "SET_HEATMAP_REGIONS":
      return {
        ...state,
        heatMapRegions: action.heatMapRegions,
      };
    case "SET_HEATMAP_SELECTED_DIVISION":
      return {
        ...state,
        selectedHeatmapDivision: action.payload.value,
        nav: true,
      };
    case "SET_HEATMAP_KPIS":
      return {
        ...state,
        heatMapKpis: action.heatMapKpis,
      };

    case "SET_KPI_CATEGORIES":
      return {
        ...state,
        kpiCategories: action.kpiCategories,
      };
    case "SET_CASCADED_FILTERS":
      const data = action.filters.data.data;
      const selectedFilterObject = {};
      const dynamicStates = {};
      const dynamicArrays = {};
      // THIS IS CORRECT
      // CREATES DYNAMIC STATES BASED ON LABELS
      data.forEach((thisFilter, index) => {
        dynamicStates[`selected${thisFilter.column_name}`] = "";
        dynamicArrays[thisFilter.column_name] = [];
        if (index == 0) {
          selectedFilterObject[thisFilter.column_name] = true;
        } else {
          selectedFilterObject[thisFilter.column_name] = false;
        }
      });
      if (action.item === "product") {
        return {
          ...state,
          selectedFilters: { ...state.selectedFilters, ...dynamicStates },
          dropDowns: { ...state.dropDowns, ...dynamicArrays },
          selectedProductfilters: selectedFilterObject,
        };
      }
      if (action.item === "store") {
        return {
          ...state,
          selectedFilters: { ...state.selectedFilters, ...dynamicStates },
          dropDowns: { ...state.dropDowns, ...dynamicArrays },
          selectedStorefilters: selectedFilterObject,
        };
      }

    case "SET_LABEL_MAPPER":
      return {
        ...state,
        labelMapper: action.mapper,
      };

    case "SET_KPI_DATA": {
      return {
        ...state,
        kpiData: action.data,
      };
    }

    case "SET_KPI_LIST": {
      return {
        ...state,
        kpiList: action.kpiList,
      };
    }
    case "SET_KPI_LABEL_MAPPER": {
      return {
        ...state,
        allKpiLabelMapper: action.allKpiLabelMapper,
      };
    }
    case "SET_LINE_DATA": {
      return {
        ...state,
        lineData: action.lineData,
      };
    }

    case "SET_SELECTED_KPI_LIST": {
      return {
        ...state,
        selectedKpiList: action.list,
      };
    }
    case "SET_KPI_COMPONENT_LIST": {
      return {
        ...state,
        kpiCompList: action.kpiCompList,
      };
    }
    case "SET_KPI_ITEMS": {
      return {
        ...state,
        kpiItems: action.kpiItems,
      };
    }
    case "SET_KPI_VALUES": {
      return {
        ...state,
        kpi_Values: action.kpi_Values,
      };
    }
    case "SET_METRIC_LABELS": {
      return {
        ...state,
        ...action.payload,
      };
    }

    case "SET_KPI_LIST_VALUES_TIMELINE": {
      return {
        ...state,
        valueKpiCardTimeLine: action.valueKpiCard,
      };
    }

    case "SET_KPI_LIST_VALUES_COMPARE": {
      return {
        ...state,
        valueKpiCardCompare: action.valueKpiCard,
      };
    }

    case "SET_BAR_GRAPH_DATA_REGION": {
      return {
        ...state,
        barGraphDataRegion: action.payload,
      };
    }

    case "SET_BAR_GRAPH_DATA_CATEGORY": {
      return {
        ...state,
        barGraphDataCategory: action.payload,
      };
    }

    case "SET_BAR_GRAPH_DATA_DEEP_DIVE": {
      return {
        ...state,
        barGraphDeepDive: action.payload,
      };
    }

    case "SET_SCATTER_GRAPH_DATA_DEEP_DIVE": {
      return {
        ...state,
        scatterGraphDeepDive: action.payload,
      };
    }

    case "SET_DROPDOWNS":
      const item = action["item"];
      const test = {
        dropDowns: {
          ...state.dropDowns,
          [item]: action.payload,
        },
      };

      return {
        ...state,
        dropDowns: {
          ...state.dropDowns,
          [item]: action.payload,
        },
      };

    case "SET_DROPDOWNS_DOWN_FILTERS":
      const itemDown = action["item"];
      const testDown = {
        dropDownsDownFilters: {
          ...state.dropDownsDownFilters,
          [itemDown]: action.payload,
        },
      };

      return {
        ...state,
        dropDownsDownFilters: {
          ...state.dropDownsDownFilters,
          [itemDown]: action.payload,
        },
      };

    case "SET_DROPDOWN_DOWN_FILTERS_EMPTY":
      return {
        ...state,
        dropDownsDownFilters: {},
      };

    case "SET_APPLIED_COMPARE_YEAR":
      return {
        ...state,
        appliedCompareYear: action.appliedCompareYear,
      };
    case "SET_EVENTS":
      return {
        ...state,
        events: action.events,
      };

    case "HANDLE_RADIO_CHECK":
      var { value } = action.payload;
      return {
        ...state,
        dateOrWeek: value,
      };
    case "SET_INITIAL_FILTERS_DEEPDIVE":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_ON_LOAD_FILTERS":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_DEPARTMENT_TRUE":
      return {
        ...state,
        selectedProductfilters: {
          ...state.selectedProductfilters,
          l1_name: true,
        },
      };

    case "SET_MAP_DATA_DEEPDIVE":
      return {
        ...state,
        storeData: action.storeData,
      };

    case "SET_NAV_TRUE":
      return {
        ...state,
        nav: true,
      };
    case "SET_MAP_VIEW":
      return {
        ...state,
        mapView: action.value,
      };

    case "SET_HANDLE_APPLY_INVENTORY":
      return {
        ...state,
        handleApplyInventory: action.clicked,
      };
    case "SET_HANDLE_APPLY_DEEPDIVE":
      return {
        ...state,
        handleApplyDeepdive: action.clicked,
      };
    case "SET_BELOW_FILTERS_EMPTY":
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          ...action.payload,
        },
      };
    case "SET_INV_KPI_VAL":
      return {
        ...state,
        invKpiValue: action.value,
      };
    case "SET_APPLIED_TIMELINE_YEAR":
      return {
        ...state,
        appliedTimelineYear: action.appliedTimelineYear,
      };
    case "SET_ABOVE_FILTERS_EMPTY":
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          ...action.payload,
        },
      };
    case "SET_LINECHART_LOADER":
      return {
        ...state,
        isLineChartLoading: action.value,
      };
    case "RESET_DEEPDIVE":
      return {
        ...state,
        resetDeepdive: action.clicked,
      };
    case "RESET_INVENTORY":
      return {
        ...state,
        resetInventory: action.clicked,
      };
    case "SET_HAS_FILTER_CHANGED":
      return {
        ...state,
        filterChanged: action.filterChanged,
      };

    case "SET_CALL_HEATMAP_APPLY":
      return {
        ...state,
        callApplyHeatmap: action.callApplyHeatmap,
      };

    case "SET_DEEPDIVE_CROSS_METRIC_DATA":
      return {
        ...state,
        scatterDeepDiveData: action.scatterDeepDiveData,
      };

    case "SET_APPLIED_YEAR":
      console.log("currYearrrrr", action.payload);
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
