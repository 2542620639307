import LoadingOverlay from "../../Utils/Loader/loader";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
export const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        // if (rest.isTenantFetchFailed) {
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: "/",
        //         state: {
        //           from: props.location,
        //         },
        //       }}
        //     />
        //   );
        // }
        if (rest.isAuthenticated) {
          const allProps = { ...props, ...rest };
          return <Component {...allProps} />;
        } else {
          if (rest.isTokenVerified) {
            toast.error("Please Login to continue...", {
              autoClose: 15000,
              hideProgressBar: true,
            });
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          } else {
            return (
              <LoadingOverlay
                loader={true}
                spinner
                text={rest.loaderText}
              ></LoadingOverlay>
            );
          }
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authReducer.isAuthenticated,
    isTokenVerified: state.authReducer.isTokenVerified,
    isTenantFetchFailed: state.authReducer.isTenantFetchFailed,
    loaderText: state.authReducer.alertProperties.Text,
  };
};

export default connect(mapStateToProps, null)(ProtectedRoute);
