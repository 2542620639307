import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { APP_PLATFORM } from "../../config/constants";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Switch from "../../Utils/switch";
import AppBar from "@material-ui/core/AppBar";
import logo from "../../assets/logo.png";
import ConfirmBox from "./confirmPopup";
import { logoutUser } from "../../actions/authActions";
import Icon from "@material-ui/core/Icon";
import Assort_en from "../../assets/home/assort_en.svg";
import Attribute_ds from "../../assets/home/attribute_ds.svg";
import Price_ds from "../../assets/home/price_ds.svg";
import Test_ds from "../../assets/home/test_ds.svg";
import Monday_ds from "../../assets/home/monday_ds.svg";
import Plan_en from "../../assets/home/plan_en.svg";
import Allocate_ds from "../../assets/home/allocate_ds.svg";
import Ada_ds from "../../assets/home/ada_ds.svg";
import { PLAN_SMART_DASHBOARD } from "../../modules/plansmart/constants-plansmart/routesConstants";
import { MONDAY_SMART_DASHBOARD } from "../../modules/mondaysmart/constants-mondaysmart/routesConstants";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "50px 150px 50px 100px",
    minHeight: "calc(100vh - 5rem)",
    height: "calc(100vh - 5rem)",
    backgroundColor: "#f9f9f9",
  },
  activeAvatar: {
    background: "#237089",
    height: "100%",
    width: "5.5rem",
  },
  unactiveAvatar: {
    height: "100%",
    width: "5.5rem",
  },

  switch: {
    marginTop: "-0.7rem",
    right: "-10px",
  },
  unactive: {
    color: "#00000094",
    padding: "3rem 0 1rem 0rem",
  },
  active: {
    color: "#00000094",
    paddingBottom: "1rem",
  },
  header: {
    padding: "0px 15px 0px 15px",
    background: "#091523",
    border: "none",
    borderRadius: "0",
    position: "sticky",

    boxShadow: "none",
    right: 0,
    zIndex: 800,
    top: 0,
    height: "5rem",
  },
  description: {
    color: theme.palette.text.secondary,
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
    textAlign: "left",
    textJustify: "inter-word",
    width: "85%",
  },
  unactiveheaderText: {
    lineHeight: "1.2",
    color: "#000000ad",
    fontSize: "1.5rem",
  },
  headerText: {
    color: "black",
    lineHeight: "1.2",
    fontSize: "1.5rem",
  },
  logo: {
    display: "flex",
    flexDirection: "row",
    width: "25%",
    height: "40px",
  },
  logoImage: {
    height: "100%",
  },
  headerContainer: {
    height: "100%",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 600,
    fontSize: "1.7rem",
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  containerCards: {
    width: "31rem",
    marginBottom: "2rem",

    "@media (max-width: 1250px)": {
      width: "26rem",
    },

    "@media (min-width: 1500px)": {
      width: "26rem",
    },
  },
  cardDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: "1",
    marginLeft: "1rem",
  },
  paper: {
    display: "flex",
    justifyContent: "space-between",
    height: theme.spacing(18),
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    borderRadius: 6,
    border: "solid 1px rgba(129, 147, 174, 0.12)",
    backgroundColor: "#fff",
    textAlign: "center",
    position: "relative",
    whiteSpace: "nowrap",

    "&:hover": {
      boxShadow: "none",
      cursor: "pointer",
      transform: "translateY(2px)",
    },

    "@media (max-width: 670px)": {
      height: theme.spacing(22),
    },
  },
}));

const Home = (props) => {
  const classes = useStyles();
  const [confirmBox, showConfirmBox] = useState(false);
  const [confirmBoxTitle, setConfirmBoxTitle] = useState("");

  const activeData = [
    // {
    //   label: "AssortSmart",
    //   desc: "Optimize assortment to maximize sell through",
    //   // url: "/filters",
    //   logo: Assort_en,
    // },
    // {
    //   label: "PlanSmart",
    //   desc: "Intelligent and automated Merchandise Financial Planning",
    //   // url: PLAN_SMART_DASHBOARD,
    //   logo: Plan_en,
    // },
    {
      label: "MondaySmart",
      desc: "Rapid performance reconciliation for Monday Reviews",
      url: MONDAY_SMART_DASHBOARD,
      logo: Monday_ds,
    },
  ];

  const unactiveData = [
    {
      label: "AttributeSmart",
      desc: "Automate Attribute Tagging",
      logo: Attribute_ds,
    },
    {
      label: "PriceSmart",
      desc: "Unified Pricing, Promotions and Markdown Optimization",
      logo: Price_ds,
    },
    {
      label: "AssortSmart",
      desc: "Optimize assortment to maximize sell through",
      // url: "/filters",
      logo: Assort_en,
    },
    {
      label: "PlanSmart",
      desc: "Intelligent and automated Merchandise Financial Planning",
      // url: PLAN_SMART_DASHBOARD,
      logo: Plan_en,
    },

    // {
    //   label: "MondaySmart",
    //   desc: "AI led insight and recommendation generator",
    //   logo: Monday_ds,
    // },
    {
      label: "AllocateSmart",
      desc: "Simplify, automate and optimize allocation process",
      logo: Allocate_ds,
    },

    {
      label: "TestSmart",
      desc: "Create, measure & monitor tests to drive margin gains",
      logo: Test_ds,
    },
    {
      label: "ADA Visual",
      desc: "Next Gen AI-led Forecasting",
      logo: Ada_ds,
    },
  ];
  useEffect(() => {
    document.title = APP_PLATFORM.APP_NAME;
  }, []);

  const renderCards = (active) => {
    const cards = active ? activeData : unactiveData;
    return cards.map((item, key) => {
      return (
        <div className={classes.containerCards}>
          <Paper
            className={classes.paper}
            id={item.label}
            onClick={(e) => {
              props.history.push(item.url);
            }}
          >
            <img src={item.logo}></img>
            <div className={classes.cardDetails}>
              <Typography className={classes.headerText} variant="subtitle1">
                {item.label}
              </Typography>
              <Typography className={classes.description} variant="subtitle1">
                {item.desc}
              </Typography>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Switch
                checked={active}
                className={classes.switch}
                onChange={(e) => {
                  e.stopPropagation();
                  active
                    ? setConfirmBoxTitle(
                        `Contact us to de-activate and try other products`
                      )
                    : setConfirmBoxTitle(
                        "Contact us to activate and try other products"
                      );
                  showConfirmBox(true);
                }}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
          </Paper>
        </div>
      );
    });
  };
  return (
    <>
      <AppBar position="fixed" className={classes.header}>
        <Grid className={classes.headerContainer} container alignItems="center">
          <Grid item xs={5}>
            <div className={classes.logo}>
              <img src={logo} alt="IA" className={classes.logoImage} />
            </div>
          </Grid>
          <Grid className={classes.heading} item xs={7}>
            {APP_PLATFORM.APP_NAME}
            <Link to="/">
              <Icon
                className="fa fa-power-off"
                style={{ fontSize: "1rem", color: "grey" }}
                onClick={() => props.logoutUser()}
              />
            </Link>
          </Grid>
        </Grid>
      </AppBar>

      {confirmBox && (
        <ConfirmBox
          title={confirmBoxTitle}
          onClose={() => showConfirmBox(false)}
          onConfirm={() => {
            showConfirmBox(false);
          }}
        />
      )}

      <div className={classes.root}>
        <div className={classes.active}>Your Active Products</div>
        <div className={classes.cardContainer}>{renderCards(true)}</div>

        <div className={classes.unactive}>Other Products</div>
        <div className={classes.cardContainer}>{renderCards(false)}</div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  logoutUser,
})(withRouter(Home));
